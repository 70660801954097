import { ContentServiceVideoObject } from '@/lib/types/api/content-service';
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils';
import CompanionState from '@/lib/types/atoms/companionState';
import createStorage from '@/lib/helpers/createStorage';
import { canAccessStorage } from '@/lib/helpers/is-storage-available';

const storage = createStorage<ContentServiceVideoObject[] | undefined>('sessionStorage')

let storedMyListVideos = undefined

if (canAccessStorage('sessionStorage')) {
  storedMyListVideos = JSON.parse(window.localStorage.getItem('myListVideos')!) as ContentServiceVideoObject[]
}

export const myListVideosAtom = atomWithStorage<ContentServiceVideoObject[] | undefined>('myListVideos', storedMyListVideos, storage);
export const myListVideosStatusAtom = atom<CompanionState>(CompanionState.NotLoaded)
